<template>
  <!-- Page content -->
  <div class="card py-3 mt-2 table-card px-1">
      <div class="d-flex flex-wrap justify-content-center" style="gap: 10px;">
          <div
              v-for="(num, index) in allNumbers"
              :key="index"
              class="numbers px-2"
              @click="selectNumbers(num, index)"
              v-show="generateShowOrNow(tableData, num)"
              :class="[
                  !num.isOpen ||
                  parseInt(num.current_amount, 10) >
                      parseInt(num.limit_amount, 10) ||
                  parseInt(num.current_amount, 10) ===
                      parseInt(num.limit_amount, 10)
                      ? 'disable'
                      : '',
                  num.isSelect ? 'active' : '',
              ]"
          >
              {{ num.number }}
              <b-progress v-if="num.isOpen ||
                  parseInt(num.current_amount, 10) <
                      parseInt(num.limit_amount, 10) ||
                  parseInt(num.current_amount, 10) !==
                      parseInt(num.limit_amount, 10)" :value="parseInt(num.current_amount)" :max="parseInt(num.limit_amount)" :variant="variantGenerate(num)"></b-progress>
          </div>
      </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';

export default {
props: {
  isTwod: {
    type: Boolean,
    default: false,
  },
  tableData: Number,
  twod: Object,
  allNumbers: Array,
  onMyEvent: Function,
},
data() {
  return {
    value: 45,
    max: 100,
    isR: false,
    numbers: [],
    //   allNumbers: this.isTwod
    //     ? this.$store.state.twod_numbers
    //     : this.$store.state.threed_numbers,
    selected_data: {
      amount: null,
    },
    errors: {},
  };
},
methods: {
  generateShowOrNow(tableData, num) {
    if(this.isTwod) return true;
    const number = parseInt(num.number, 10);
    if (tableData === 1 && number < 100) {
      return true;
    } else if (tableData === 2 && number >= 100 && number < 200) {
      return true;
    } else if (tableData === 3 && number >= 200 && number < 300) {
      return true;
    } else if (tableData === 4 && number >= 300 && number < 400) {
      return true;
    } else if (tableData === 5 && number >= 400 && number < 500) {
      return true;
    } else if (tableData === 6 && number >= 500 && number < 600) {
      return true;
    } else if (tableData === 7 && number >= 600 && number < 700) {
      return true;
    } else if (tableData === 8 && number >= 700 && number < 800) {
      return true;
    } else if (tableData === 9 && number >= 800 && number < 900) {
      return true;
    } else if (tableData === 10 && number >= 900) {
      return true;
    }
  },
  variantGenerate(number) {
    const currentAmount = parseInt(number.current_amount, 10);
    const limitAmount = parseInt(number.limit_amount, 10);

    const percentage = (currentAmount / limitAmount) * 100;

    if (percentage <= 50) {
      return 'success';
    } if (percentage <= 75) {
      return 'warning';
    }
    return 'danger';
  },
  selectNumbers(num, index) {
    if(this.isTwod){
      const newNum = this.allNumbers[index].number.toString();
      const firstChar = newNum[0]; // Get the first character
      const secondChar = newNum[1];
      let newIndex = null;

      let isBot = null;
      let isTop = null;

      if (this.twod.top_numbers) {
        isBot = this.twod.top_numbers.includes(secondChar);
        isTop = this.twod.top_numbers.includes(firstChar);
      }

      if (isBot && !isTop) {
        const botIndex = this.numbers.findIndex((obj) => obj.number.toString() === (secondChar + firstChar));
        if (botIndex !== -1) return false;
      }

      this.allNumbers[index].isSelect = !this.allNumbers[index].isSelect;

      if (isTop) {
        newIndex = this.allNumbers.findIndex((obj) => obj.number.toString() === (secondChar + firstChar));
        this.allNumbers[newIndex].isSelect = !this.allNumbers[newIndex].isSelect;
      }

      if (this.allNumbers[index].isSelect) {
        this.numbers.push(num);
        if (isTop) {
          this.numbers.push(this.allNumbers[newIndex]);
        }
      } else {
        if (isTop) {
          this.numbers.splice(newIndex, 1);
          const topIndex = this.numbers.findIndex((obj) => obj.number.toString() === this.allNumbers[newIndex].number.toString());
          this.numbers.splice(topIndex, 1);
        }
        const index = this.numbers.indexOf(num);
        this.numbers.splice(index, 1);
      }
      this.$emit('numbers', this.numbers);
      return true;
    } else {
      this.allNumbers[index].isSelect = !this.allNumbers[index].isSelect;
      if (this.allNumbers[index].isSelect) {
        this.numbers.push(num);
      } else {
        const index = this.numbers.indexOf(num);
        this.numbers.splice(index, 1);
      }
      this.$emit('numbers', this.numbers);
    }
  },
  dialog(text) {
    Dialog.alert({
      message: text,
      confirmButtonText:
                  this.$store.state.language === 'en'
                    ? 'Go Back'
                    : 'နောက်သို့ပြန်သွားမည်။',
    }).then(() => {
      // on close
    });
  },
  // setToPreviewVoucher() {
  // //   this.errors = {};
  //   //   if (!this.selected_data.amount) {
  //   //     this.errors.amount = this.$store.state.language === 'en'
  //   //       ? 'Please enter amount'
  //   //       : 'ငွေပမာဏထည့်ပေးပါ။';
  //   //     return false;
  //   //   }
  //   //   if (this.selected_data.amount < 100) {
  //   //     this.errors.amount = this.$store.state.language === 'en'
  //   //       ? 'Please enter at least 100 MMK'
  //   //       : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
  //   //     return false;
  //   //   }
  //   this.setAmount(this.selected_data.amount);
  //   this.$emit('numbers', this.numbers);
  //   this.numbers = [];
  //   this.selected_data = {};
  //   $('#amount').modal('hide');
  //   this.resultAllSelectedNumbers();
  //   return true;
  // },
  resultAllSelectedNumbers() {
    for (let i = 0; i < this.allNumbers.length; i += 1) {
      this.allNumbers[i].isSelect = false;
    }
  },
  createDateWithTimeZone() {
    const moment = require('moment-timezone');
    const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
    // Extract individual components from moment object
    const year = dateInTimeZone.year();
    const month = dateInTimeZone.month();
    const day = dateInTimeZone.date();
    const hour = dateInTimeZone.hour();
    const minute = dateInTimeZone.minute();
    const second = dateInTimeZone.second();
    const millisecond = dateInTimeZone.millisecond();

    // Create a new Date object with the extracted components
    const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
    return newDateObject;
  },
  // setAmount(amount) {
  //   const currentHour = this.createDateWithTimeZone().getHours();
  //   const draw = currentHour < 12 ? '12:00' : '4:30';
  //   for (let i = 0; i < this.numbers.length; i += 1) {
  //     this.numbers[i].solo_amount = amount;
  //     this.numbers[i].draw = draw;
  //   }
  // },
  // showModalBox() {
  //   $('#amount').modal('show');
  // },
},
mounted() {
  this.onMyEvent(() => {
    this.numbers = [];
    this.resultAllSelectedNumbers();
  });
},
};
</script>
<style scoped>
.progress{
  height: 6px !important;
}
/* .bet-container {
  position: fixed;
  bottom: 0;
  margin-bottom: 70px;
  width: 100px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.25em;
  color: white;
  z-index: 100;
  visibility: visible;
  opacity: 1;
} */
.numbers {
  /* width: 55px;
  height: 55px; */
  flex: 1 1 calc(20% - 10px); /* Adjust the width to 20% minus the gap */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  border-radius: 10px;
  background: white;
  color: black;
  font-weight: 600;
}
.numbers.active {
  border-radius: 10px;
  background: rgb(109 145 248);
  color: white;
}
.numbers.disable {
  border-radius: 10px;
  background: #7c7676;
  color: white;
}
@media (max-width: 768px) {
  .table-data {
      padding: 5px;
  }
  .table-card {
      margin-bottom: 0px;
      background: transparent !important;
  }
}
</style>
