<template>
  <div
    class="d-flex flex-column mt-5 main-wrapper"
    style="
      padding-left: 6px !important;
      padding-right: 6px !important;
      height: 80vh;
    "
  >
    <div class="account-container-wrapper d-flex pl-0 mt-2 py-2">
      <div class="col-lg-6 col-5 my-auto">
        <img :src="$store.state.authUser.gender === 'female' ? 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1b5a569c-c421-446c-86a8-98da438fbf00/public' : 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2bc293e0-e453-442c-3dcd-a3572fece800/public'" style="width: auto; height: 100px; border-radius: 20%;" alt="">
      </div>

      <div class="col-lg-6 col-7 p-0">
        <div class="pt-2">
          <div class="d-flex">
            <p class="MuiTypography-root MuiTypography-body1 css-17h40hp mb-1" style="width: 100px"> 
                        {{
                            $store.state.language === "en"
                                ? "Username"
                                : "အမည်"
                        }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">: {{$store.state.authUser.name}} </p>
          </div>
          <div class="d-flex">
            <p class="MuiTypography-root MuiTypography-body1 css-17h40hp mb-1" style="width: 100px">
                        {{
                            $store.state.language === "en"
                                ? "Balance"
                                : "လက်ကျန်ငွေ"
                        }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">:
              <b>{{ Number($store.state.amount).toLocaleString() }}</b>
            {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </p>
          </div>
          <div class="d-flex">
            <p class="MuiTypography-root MuiTypography-body1 css-17h40hp mb-1" style="width: 100px">
                        {{
                            $store.state.language === "en"
                                ? "Phone"
                                : "ဖုန်းနံပါတ်"
                        }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">: {{ $store.state.authUser.phone }} </p>
          </div>
          <div class="d-flex" v-if="$store.state.authUser.commissioner">
            <p class="css-17h40hp mb-1 text-nowrap" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Agent Referral"
                                : "အေးဂျင့်ကုဒ်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.commissioner }}</p>
          </div>
        </div>
      </div>
  </div>



    <div class="account-container-wrapper mt-2 col-lg-12 col-12 pl-0 side-nav-bar-custom">
      <nav class="side-nav card my-2" style="border-radius: 10px;">
        <div>
          <div>
            <!-- Nav items -->
            <ul class="navbar-nav-custom">
              <li class="nav-items">
                <div class="nav-link">
                  <i class="fas fa-user mr-3"></i>
                  <span class="nav-link-text">{{
                    $store.state.language === 'en'
                      ? `Nick Name - ${$store.state.authUser.user_name || ''}`
                      : `အမည်ပြောင် - ${$store.state.authUser.user_name || ''}`
                  }}</span>
                </div>
              </li>
              <li
                class="nav-items"
                data-toggle="modal"
                data-target="#editUserPassword"
              >
                <div class="nav-link d-flex justify-content-between">
                  <div>
                    <i class="fas fa-key mr-3"></i>
                    <span class="nav-link-text">{{
                      $store.state.language === 'en'
                        ? 'Change Password'
                        : 'လျှို့ဝှက်နံပါတ် ပြောင်းမည်'
                    }}</span>
                  </div>
                  <div>
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </div>
              </li>
              <li
                class="nav-items"
                @click="() => $router.push('/rule')"
              >
                <div class="nav-link d-flex justify-content-between">
                  <div>
                    <i class="fas fa-book mr-3"></i>
                    <span class="nav-link-text">{{
                      $store.state.language === 'en'
                        ? 'Rules'
                        : 'စည်းကမ်းချက်များ'
                    }}</span>
                  </div>
                  <div>
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </div>
              </li>
              <li class="nav-items">
                <div
                  class="nav-link d-flex justify-content-between"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div id="navbar-language">
                    <i class="fas fa-language mr-3"></i>
                    <span class="nav-link-text">{{
                      $store.state.language === 'en'
                        ? 'Change Language'
                        : 'ဘာသာစကား ပြောင်းမည်'
                    }}</span>
                  </div>

                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbar-language"
                  >
                    <a class="dropdown-item" @click="setLangLocalstorage('en')"
                      ><img
                        src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/united-kingdom.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />
                      {{
                        $store.state.language === 'en' ? 'English' : 'အဂ်လိပ်စာ'
                      }}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setLangLocalstorage('mm')"
                      ><img
                        src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/myanmar.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{
                        $store.state.language === 'en'
                          ? 'Myanmar (Burmese)'
                          : 'မြန်မာ (ဗမာစာ)'
                      }}</a
                    >
                  </div>

                  <div>
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </div>
              </li>
              <li class="nav-i">
                <div class="ml-3 nav-link">
                  Version :
                  <span class="text-danger">{{
                    $store.state.androidVersion
                  }}</span>
                </div>
              </li>
            </ul>
            <!-- Divider -->
            <hr class="my-3" />
            <!-- Navigation -->
            <ul class="navbar-nav-custom mb-md-3">
              <li class="nav-items">
                <div class="nav-link active active-pro" @click="logout()">
                  <i class="ni ni-lock-circle-open text-danger mr-3"></i>
                  <span class="nav-link-text text-danger">{{
                    $store.state.language === 'en' ? 'Log Out' : 'ထွက်မည်'
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <!-- ==== modal for user password edit ==== -->
    <div
      class="modal fade"
      id="editUserPassword"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-form"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-sm"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <div class="card border-0 mb-0">
              <div class="card-body px-lg-5 py-lg-5">
                <div class="form-group">
                  {{
                    $store.state.language === 'en'
                      ? 'Old Password'
                      : 'လျို့ဝှက်နံပါတ်အဟောင်း။'
                  }}
                  <div class="mt-2 input-group">
                    <input class="form-control" v-model="password.old" />
                  </div>
                  <span class="text-danger">{{ errors.old }}</span>
                </div>
                <div class="form-group">
                  {{
                    $store.state.language === 'en'
                      ? 'New Password'
                      : 'လျို့ဝှက်နံပါတ်အသစ်။'
                  }}
                  <div class="mt-2 input-group">
                    <input class="form-control" v-model="password.new" />
                  </div>
                  <span class="text-danger">{{ errors.new }}</span>
                </div>
                <div class="form-group">
                  {{
                    $store.state.language === 'en'
                      ? 'Confirm Password'
                      : 'အတည်ပြုလျို့ဝှက်နံပါတ်။'
                  }}
                  <div class="mt-2 input-group">
                    <input class="form-control" v-model="password.confirm" />
                  </div>
                  <span class="text-danger">{{
                    errors.confirm || errors.confirmPassword
                  }}</span>
                </div>
                <div class="text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="changeNewPassword"
                  >
                    <div v-if="!isConfirm">
                      {{
                        $store.state.language === 'en'
                          ? 'Confirm'
                          : 'အတည်ပြုမည်'
                      }}
                    </div>

                    <Loading
                      color="#ffffff"
                      v-if="isConfirm"
                      size="24"
                      class="px-4 py-0"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      password: {
        user_id: this.$store.state.authUser.id,
      },
      isConfirm: false,
      errors: {},
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
    },
    changeNewPassword() {
      this.errors = {};
      if (!this.password.old) {
        this.errors.old =
          this.$store.state.language === 'en'
            ? 'Old password required'
            : 'လျို့ဝှက်နံပါတ်အဟောင်း ထည့်ပါ။';
        return false;
      }
      if (!this.password.new) {
        this.errors.new =
          this.$store.state.language === 'en'
            ? 'New password required'
            : 'လျို့ဝှက်နံပါတ်အသစ် ထည့်ပါ။';
        return false;
      }
      if (!this.password.confirm) {
        this.errors.confirm =
          this.$store.state.language === 'en'
            ? 'Confirm password required'
            : 'အတည်ပြုလျို့ဝှက်နံပါတ် ထည့်ပါ။';
        return false;
      }
      if (this.password.new !== this.password.confirm) {
        this.errors.confirmPassword =
          this.$store.state.language === 'en'
            ? 'New passwords not match'
            : 'လျှို့ဝှက်နံပါတ် မတူညီပါ။';
        return false;
      }
      this.isConfirm = true;
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure?'
            : 'သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မလုပ်တော့ပါ။',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ။',
      })
        .then(() => {
          this.updateUser();
        })
        .catch(() => {});
      this.isConfirm = false;
      return true;
    },
    async updateUser() {
      try {
        const res = await axios.post(
          '/auth/userPasswordChange',
          this.password,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        Toast.success(
          this.$store.state.language === 'en' ? 'Success' : 'အောင်မြင်သည်။'
        );
        this.password = {};
        this.errors = {};
        $('#editUserPassword').modal('hide');
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။'
        );
      }
    },
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if (authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
  },
};
</script>
<style scoped>
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500 !important;
}
.error {
  margin-bottom: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  font-weight: bold;
}
.account-container-wrapper {
    width: auto;
    height: 200px;
    border-radius: 10px;
    color: black;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
</style>
