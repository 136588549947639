<template>
    <div>
        <!-- Page content -->
        <div class="ml-auto mr-auto card-upgrade">
            <div class="card">
                <div
                    class="
                        card-header
                        text-center
                        font-weight-bold
                        border-bottom-0
                    "
                >
                    {{
                        $store.state.language === "en"
                            ? "Betslip Preview"
                            : "ဘောက်ချာကြိုတင်မြင်ရမူ"
                    }}
                </div>
                <div class="card-body pt-0 px-1 text-sm">
                    <div class="mb-3 d-flex justify-content-between">
                        <div>
                            <div>
                                Draw :
                                <b>{{
                                    getFBOrder.fb_matches.length > 0
                                        ? getFBOrder.fb_matches[0].draw_date
                                        : ""
                                }}</b>
                            </div>
                        </div>
                        <div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Boards"
                                        : "အရေအတွတ်"
                                }}
                                :
                                <b>{{
                                    getFBOrder.fb_matches.length > 0
                                        ? getFBOrder.fb_matches.length
                                        : 0
                                }}</b>
                            </div>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Total"
                                        : "စုစုပေါင်းငွေ"
                                }}
                                :
                                <b
                                    >{{ onlyTotalAmount() }}
                                    {{
                                        $store.state.language === "en"
                                            ? "MMK"
                                            : "ကျပ်"
                                    }}</b
                                >
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table style="width: 100%">
                            <thead>
                                <tr
                                    style="
                                        border-bottom: 1px solid rgb(92, 95, 147);
                                    "
                                >
                                    <th class="text-center text-sm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Home vs Away"
                                                : "Home vs Away"
                                        }}
                                    </th>
                                    <th class="text-center text-sm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Selected"
                                                : "Selected"
                                        }}
                                    </th>
                                    <th
                                        class="text-center text-sm"
                                        v-if="type === 'body' || type === '1x2' || type === 'correctScore'"
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Amount"
                                                : "ငွေပမာဏ"
                                        }}
                                    </th>
                                    <th class="text-center text-sm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Action"
                                                : "Action"
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        data, index
                                    ) in JSON.parse(JSON.stringify(getFBOrder.fb_matches))"
                                    :key="index"
                                >
                                    <td class="text-center text-xs pb-2">
                                        <div class="d-flex justify-content-center mt-1"> 
                                            <span style="font-size: 0.65rem !important; color: #2359ed">
                                                {{data.league}}
                                            </span>
                                            <span style="font-size: 0.65rem !important; color: #2359ed">
                                                &nbsp; - &nbsp;{{ matchTimeOnly(data.start_time)}}
                                            </span>
                                        </div>

                                        <div class="d-flex justify-content-center">
                                            <!-- {{ firstMatchNo(data) }}.{{
                                                data.first_team
                                            }} -->
                                            <!-- <div> -->
                                                {{
                                                    $store.state.language === "en"
                                                        ? data.first_team_en
                                                        : data.first_team
                                                }}
                                                <span class="text-danger" v-if="!data.selected_score && !data.correct_score"
                                                    >{{
                                                        data.first_team ===
                                                            data.kyayed_team &&
                                                        !data.goal_up_down
                                                            ? `&nbsp;(${
                                                                getFBOrder.type ===
                                                                "maung"
                                                                    ? data.maung_kyay
                                                                    : data.body_kyay ===
                                                                        "~"
                                                                    ? "=="
                                                                    : getFBOrder.type ===
                                                                        "maung"
                                                                    ? data.maung_kyay
                                                                    : data.body_kyay
                                                            })&nbsp;`
                                                            : ""
                                                    }}{{
                                                        data.goal_up_down
                                                            ? `&nbsp;(GP
                                                            ${
                                                                getFBOrder.type ===
                                                                "maung"
                                                                    ? data.maung_goal_kyay
                                                                    : data.body_goal_kyay
                                                            }

                                                    )&nbsp;`
                                                            : ""
                                                    }}</span
                                                >
                                            <!-- </div> -->

                                            {{ data.goal_up_down ? "" : "-" }}
                                            <!-- {{ secondMatchNo(data) }}.{{
                                                data.second_team
                                            }} -->
                                            {{
                                                $store.state.language === "en"
                                                    ? data.second_team_en
                                                    : data.second_team
                                            }}
                                            <span class="text-danger" v-if="!data.selected_score  && !data.correct_score">{{
                                                data.second_team ===
                                                    data.kyayed_team &&
                                                !data.goal_up_down
                                                    ? `&nbsp;(${
                                                          getFBOrder.type ===
                                                          "maung"
                                                              ? data.maung_kyay
                                                              : data.body_kyay ===
                                                                "~"
                                                              ? "=="
                                                              : getFBOrder.type ===
                                                                "maung"
                                                              ? data.maung_kyay
                                                              : data.body_kyay
                                                      })&nbsp;`
                                                    : ""
                                            }}</span>
                                        </div>
                                    </td>
                                    <td class="text-center text-xs pt-3">
                                        <div>
                                            <!-- 1x2  -->
                                            <span v-if="data.selected_score && data.selected_score === 'first_score'">
                                                {{
                                                    $store.state.language === "en"
                                                        ? data.first_team_en
                                                        : data.first_team
                                                }}  ( <span class="text-danger"><b>x {{ data.selected_kyay }}</b></span> )
                                            </span>
                                            <span v-if="data.selected_score && data.selected_score === 'second_score'">
                                                {{
                                                    $store.state.language === "en"
                                                        ? data.second_team_en
                                                        : data.second_team
                                                }}  ( <span class="text-danger"><b>x {{ data.selected_kyay }}</b></span> )
                                            </span>
                                            <span v-if="data.selected_score && data.selected_score === 'draw_score'">
                                                {{
                                                    $store.state.language === "en"
                                                        ? 'Draw'
                                                        : 'Draw'
                                                }}  ( <span class="text-danger"><b>x {{ data.selected_kyay }}</b></span> )
                                            </span>
                                            <!-- end 1x2  -->

                                            <!-- start correct score  -->
                                            <span v-if="data.correct_score">
                                                {{ data.correct_score }} ( <span class="text-danger"><b>x {{ data.correct_odds }}</b></span> )
                                            </span>
                                             <!-- end correct score  -->


                                            <span v-if="!data.goal_up_down && !data.sone_ma">
                                                {{
                                                    data.selected_team
                                                }}
                                            </span>

                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.goal_up_down === 'up'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Goal Up" : "ဂိုးပေါ်"
                                                }}
                                            </span>
                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.goal_up_down === 'down'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Goal Down" : "ဂိုးအောက်"
                                                }}
                                            </span>

                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.sone_ma === 'sone'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Even" : "စုံ"
                                                }}
                                            </span>
                                            <span
                                                class="text-danger text-nowrap"
                                                v-if="data.sone_ma === 'ma'"
                                                >
                                                {{
                                                    $store.state.language === "en" ? "Odd" : "မ"
                                                }}
                                            </span>

                                        </div>
                                    </td>
                                    <td
                                        class="text-center text-xs pt-3"
                                        v-if="type === 'body' || type === '1x2' || type === 'correctScore'"
                                    >
                                        <div v-if="isEdit !== index">
                                            {{ data.bet_amount }}
                                        </div>

                                        <input
                                            type="number"
                                            name=""
                                            id=""
                                            v-else
                                            v-model="data.bet_amount"
                                            :min="3000"
                                            class="form-control text-center"
                                            @change="
                                                changeParentAmount(
                                                    data.bet_amount,
                                                    index
                                                )
                                            "
                                        />
                                    </td>

                                    <td class="p-0 d-flex justify-content-end pt-3">
                                        <div v-if="type === 'body' || type === '1x2' || type === 'correctScore'">
                                            <i
                                                class="fas fa-edit text-sm"
                                                @click="isEdit = index"
                                                v-if="isEdit !== index"
                                            ></i>
                                            <i
                                                class="fas fa-check text-sm"
                                                @click="isEdit = null"
                                                v-else
                                            ></i>
                                        </div>
                                        <div @click="removeNumber(index)">
                                            <i class="fas fa-trash text-sm m-auto"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-4 pl-3">
                            <span class="text-xs text-dark text-uppercase">
                                    {{
                                        $store.state.language === "en"
                                            ? "Estimate amount => "
                                            : "ခန့်မှန်း အနိုင်ရငွေ => "
                                    }}</span><span class="text-success text-md">{{estimatedAmount()}}</span> <span class="text-danger text-xs">MMK</span>
                        </div>
                        <div class="d-flex">
                            <button
                                class="
                                    btn btn-round btn-primary
                                    buttons
                                    px-5
                                    my-3
                                    mx-auto
                                "
                                @click="!isBet ? finalBet() : ''"
                                :class="isBet ? 'disabled' : ''"
                            >
                                <div v-if="!isBet">
                                    {{
                                        $store.state.language === "en"
                                            ? "Bet"
                                            : "ထိုးမည်"
                                    }}
                                </div>
                                <Loading
                                    color="#ffffff"
                                    v-if="isBet"
                                    size="24"
                                    class="px-2 py-0"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- === only amount === -->
            <div class="col-4">
                <div
                    class="modal fade"
                    id="fb-voucher"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal- modal-dialog-centered"
                        role="document"
                    >
                        <div class="modal-content">
                            <div
                                class="text-center modal-cross"
                                @click="closeModal"
                            >
                                X
                            </div>
                            <div class="modal-body">
                                <FBVoucher
                                    :type="type"
                                    :datas="newMatches"
                                    :currentTime="currentTime()"
                                    :amount="amount"
                                    :slipId="voucherId"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions, mapGetters } from 'vuex';
import FBVoucher from './FBVoucher.vue';

export default {
  components: { FBVoucher, Loading },
  props: ['type', 'amount'],
  data() {
    return {
      isEdit: null,
      remark: null,
      newRemark: null,
      isBet: false,
      voucherId: this.slipId(),
      football: {},
      newMatches: {},
      voucherLength: null,
      errorAmount: null,
    };
  },
  computed: {
    ...mapGetters(['getFBOrder']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    matchTimeOnly(time) {
        return moment(time).format("LT")
    },
    estimatedAmount() {
        if(this.type === 'body') {
            let totalAmount = 0;
            this.getFBOrder.fb_matches.forEach(match => {
                const miniTotal = match.bet_amount * 2;
                let comm = 0;
                if(match.is_big && !match.sone_ma){
                    comm = this.football.big_match_body_fees;
                } else if(!match.is_big && !match.sone_ma){
                    comm = this.football.small_match_body_fees;
                } else if(match.sone_ma){
                    comm = 10;
                }
                totalAmount += miniTotal - (match.bet_amount * comm / 100);
            })
            return totalAmount
        }
        if(this.type === '1x2') {
            let totalAmount = 0;
            this.getFBOrder.fb_matches.forEach(match => {
                const miniTotal = match.bet_amount * match.selected_kyay;
                let comm = 5;
                totalAmount += miniTotal - ((miniTotal - match.bet_amount) * comm) / 100;
            })
            return totalAmount
        }
        if(this.type === 'correctScore') {
            let totalAmount = 0;
            this.getFBOrder.fb_matches.forEach(match => {
                const miniTotal = match.bet_amount * match.correct_odds;
                let comm = 5;
                // if(match.is_big && !match.sone_ma){
                //     comm = this.football.big_match_body_fees;
                // } else if(!match.is_big && !match.sone_ma){
                //     comm = this.football.small_match_body_fees;
                // } else if(match.sone_ma){
                //     comm = 10;
                // }
                totalAmount += miniTotal - ((miniTotal - match.bet_amount) * comm) / 100;
            })
            return totalAmount
        }
        if(this.type === 'maung') {
            const matchesCount = this.getFBOrder.fb_matches.length;
            const comm = matchesCount <= 2 ? this.football.up_two_maung_fees : (matchesCount > 8 ? this.football.above_eight_maung_fees : this.football.three_to_eight_maung_fees)
            let totalAmount = this.onlyTotalAmount();
            this.getFBOrder.fb_matches.forEach((match, index) => {
                totalAmount = totalAmount * 2;
            })
            const amount = totalAmount - (totalAmount * (comm / 100))
            return amount
        }
        
    },
    firstMatchNo(data) {
      if (data?.match_no) return data?.match_no * 2 - 1;
      return true;
    },
    secondMatchNo(data) {
      if (data?.match_no) return data?.match_no * 2;
      return true;
    },
    selectedMatchNo(data) {
      if (data?.selected_team === data?.first_team) return this.firstMatchNo(data);
      if (data?.selected_team === data?.second_team) return this.secondMatchNo(data);
      return true;
    },
    changeParentAmount(value, index) {
        console.log('value', value)
        console.log('index', index)
        if(value < 3000){
            const message = this.$store.state.language === 'en'
          ? `Please enter amount more than 3000`
          : `3000 ထက်ပိုထည့်ပေးပါ။`;

            Dialog.alert({
            message: message,
            confirmButtonText: 'OK',
            }).then(() => {
                this.isEdit = null;
            });
            return false;
        } else {
            this.getFBOrder.fb_matches[index].bet_amount = value;
        }   
    },
    closeModal() {
      $('#voucher').modal('hide');
    },
    removeNumber(id) {
      this.getFBOrder.fb_matches.splice(id, 1);
    },
    /* eslint-disable */
        async finalBet() {
            if (this.isBet) {
                return false;
            }
            if (this.voucherId === this.slipId()) {
                return false;
            }

            this.isBet = true;
            const userRes = await this.fetchUser();
            localStorage.setItem('userData', JSON.stringify(userRes));
            this.voucherId = this.slipId();
            this.$store.commit("setAmount", userRes.amount);
            try {
                if (this.getFBOrder.fb_matches.length > 0) {
                    if (!this.football.is_maung_open && this.type === "maung") {
                        const dataError =
                            this.$store.state.language === "en"
                                ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                                : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )";
                        this.dialog(dataError);
                        this.isBet = false;
                        this.voucherId = undefined;
                        return false;
                    }
                    if (!this.football.is_body_open && this.type === "body") {
                        const dataError =
                            this.$store.state.language === "en"
                                ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                                : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )";
                        this.dialog(dataError);
                        this.isBet = false;
                        this.voucherId = undefined;
                        return false;
                    }
                    if (
                        this.getFBOrder.fb_matches.length <
                            this.football.min_maung_teams &&
                        this.type === "maung"
                    ) {
                        const dataError =
                            this.$store.state.language === "en"
                                ? `Please select teams ${this.football.min_maung_teams} or more ${this.football.min_maung_teams} teams.`
                                : `ကျေးဇူးပြု၍ ${this.football.min_maung_teams} ​သင်း (သို့) ${this.football.min_maung_teams} သင်းထက်ပိုရွေးပေးပါ။`;
                        this.dialog(dataError);
                        this.isBet = false;
                        this.voucherId = undefined;
                        return false;
                    }
                    if (
                        this.getFBOrder.fb_matches.length === 0 &&
                        this.type === "body"
                    ) {
                        const dataError =
                            this.$store.state.language === "en"
                                ? `Please select teams at least one team.`
                                : `ကျေးဇူးပြု၍ အနည်းဆုံးတစ်သင်းရွေးပေးပါ။`;
                        this.dialog(dataError);
                        this.isBet = false;
                        this.voucherId = undefined;
                        return false;
                    }
                    if (
                        this.getFBOrder.fb_matches.length >
                            this.football.max_maung_teams &&
                        this.type === "maung"
                    ) {
                        const dataError =
                            this.$store.state.language === "en"
                                ? `Please select teams ${this.football.max_maung_teams} or less ${this.football.max_maung_teams} teams.`
                                : `ကျေးဇူးပြု၍ ${this.football.max_maung_teams} ​သင်း (သို့) ${this.football.max_maung_teams} သင်းအောက်သာရွေးပေးပါ။`;
                        this.dialog(dataError);
                        this.isBet = false;
                        this.voucherId = undefined;
                        return false;
                    }
                    if (this.$store.state.amount >= this.onlyTotalAmount()) {
                        this.getFBOrder.total_amount = this.onlyTotalAmount();
                        this.getFBOrder.voucher_id = this.voucherId;
                        
                        const res = await axios.post(
                            "/fb-vouchers",
                            this.getFBOrder, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                                },
                            }
                        );
                        this.checkCurrentVouchers();
                        if (res.data.message) {
                            this.voucherId = undefined;
                            this.dialog(
                                res.data.message
                                // this.$store.state.language === "en"
                                //     ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                                //     : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )"
                            );
                        } else {

                            this.$store.commit(
                                "setAmount",
                                (this.$store.state.amount -=
                                    this.onlyTotalAmount())
                            );
                            const userRes = await this.fetchUser();
                            localStorage.setItem('userData', JSON.stringify(userRes));

                            this.newMatches = JSON.parse(
                                JSON.stringify(this.getFBOrder.fb_matches)
                            );
                            Toast.success(
                                this.$store.state.language === 'en'
                                    ? 'Success'
                                    : 'အောင်မြင်သည်။',
                                );
                            this.$emit("remove");
                            this.isBet = false;
                            // $("#fb-voucher").modal("show");
                            $("#mobile-voucher").modal("hide");

                        }
                    } else {
                        const balanceError =
                            this.$store.state.language === "en"
                                ? "You Don't Have Sufficient Balance"
                                : "အကောင့်ထဲတွင် ပိုက်ဆံအလုံအလောက် မရှိပါ။";
                        this.dialog(balanceError);
                        this.voucherId = undefined;
                    }
                } else {
                    const dataError =
                        this.$store.state.language === "en"
                            ? "Please select some teams!"
                            : "အသင်းတစ်ချို့ ရွေးပေးပါ။";
                    this.dialog(dataError);
                    this.voucherId = undefined;
                }
                this.isBet = false;
            } catch (error) {
                this.isBet = false;
            }
            return true;
        },

        dialog(text) {
            Dialog.alert({
                message: text,
                confirmButtonText:
                    this.$store.state.language === "en"
                        ? "Go Back"
                        : "နောက်သို့ပြန်သွားမည်။",
            }).then(() => {
                // on close
            });
        },

        currentTime() {
            return moment(new Date()).format("lll");
        },
        totalAmount() {
            let total = 0;
            for (let i = 0; i < this.newDatas.length; i += 1) {
                total += parseInt(this.newDatas[i].solo_amount, 10);
            }
            if (this.$auth.check("comissioner")) {
                const comissionerValue =
                    total *
                    ((this.isTwod
                        ? this.$store.state.authUser["2d_comission"]
                        : this.$store.state.authUser["3d_comission"]) /
                        100);
                total -= comissionerValue;
            }
            return total;
        },
        async checkCurrentVouchers() {
            try {
                const res = await axios.post("/vouchers/check", {
                    type: this.type,
                }, {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                this.voucherLength = res.data.voucherLength;
            } catch (error) {
                console.log(error);
            }
        },
        slipId() {
            return this.voucherLength;
        },
        onlyTotalAmount() {
            let total = 0;
            if (this.type === "maung") {
                return parseInt(this.getFBOrder.fb_matches[0]?.bet_amount, 10);
            }
            for (let i = 0; i < this.getFBOrder.fb_matches.length; i += 1) {
                total += parseInt(this.getFBOrder.fb_matches[i].bet_amount, 10);
            }
            return total;
        },
        async fetchFootball() {
            try {
                const res = await axios.get("/football");
                this.football = res.data.data[0];
            } catch (error) {
                console.log(error);
            }
        },
    },
    computed: {
        ...mapGetters(["getFBOrder"]),
    },
    destroyed() {
        this.$store.commit("setTwodSlip", []);
        this.$store.commit("setThreedSlip", []);
    },
    mounted() {
        this.checkCurrentVouchers();
        this.fetchFootball();
    },
};
</script>
<style scoped>
.card-upgrade {
    min-height: 400px;
}
.card-body {
    padding-bottom: 0px;
}
.action-icons {
    font-size: 15px;
}
td i {
    font-size: 18px;
    padding: 5px;
    color: #2dce89;
    cursor: pointer;
}
td i:hover {
    color: grey;
}
td .fa-trash {
    color: red;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
    .hidden {
        display: none;
    }
    .card-upgrade {
        min-height: 100px;
    }
    .form-control {
        height: 2.5rem !important;
    }
}
</style>
