<template>
  <div style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/66096b97-21ef-45e6-3246-81ddd53dd000/public'); min-height: 100vh; height: auto; background-size: cover;">
      <!-- <Sticky>
        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            :text="noticeText"
            v-if="noticeText"
        />
      </Sticky> -->

      <!-- <h1 class="pt-4 pb-2 text-center text-white">
          {{ $store.state.language === "en" ? "2D" : "နှစ်လုံး" }}
      </h1> -->

    <Sticky class="normal-box pt-3 px-2">
      <div style="background: #F6DCB9; border-radius: 10px;" class="col-12 mx-auto py-3">
          <div class="d-flex justify-content-between">
              <div class="col-5 p-0">
                  <button class="btn btn-dark" style="width: 100%; border-radius: 7px;" @click="showGroupBox">
                      {{ $store.state.language === "en" ? "Quick Bet" : "အမြန်ရွေးထိုးမည်။" }}
                  </button>
              </div>
              <div class="col-7 p-0 pl-2">
                  <!-- {{ $store.state.language === "en" ? "Amount" : "ငွေပမာဏ" }} -->
                  <div class="form-group mb-0">
                          <input
                              type="number"
                              ref="amount"
                              v-model="numberAmount"
                              class="form-control amount"
                              placeholder="100.00 MMK"
                              style="height: 32px; border-radius: 7px; border: none;"
                          />
                  </div>
              </div>
          </div>
          <hr class="mt-3 mb-2">
          <div class="d-flex justify-content-between">
              <div>
                <b>
                    <i class="fas fa-wallet my-auto mr-1 text-dark"></i> :
                    {{
                        Number($store.state.amount).toLocaleString()
                    }} {{ $store.state.language === "en" ? "MMK" : "ကျပ်" }}
                </b>
              </div>
              
              <div>
                 Bet Close: <b>{{betClose()}}</b>
              </div>
          </div>
          <hr class="mt-2 mb-3">
          <div class="d-flex justify-content-between">
              <div class="d-flex">
                    <span class="px-2 my-auto py-1 number-reverse" :class="isReverse ? 'active' : ''" @click="isReverse = !isReverse">R</span>
              </div>
              <div class="col-3 p-0">
                <button class="btn btn-dark" style="width: 100%; border-radius: 7px;"  @click="$router.push('/user/dream-books?type=2d')">
                        {{
                            $store.state.language === "en"
                                ? "Dream"
                                : "အိမ်မက်"
                        }}
                </button>
              </div>
              <div class="col-5 p-0">
                  <button class="btn btn-dark" style="width: 100%; border-radius: 7px;"  @click="preBet">
                      <div v-if="!isBet">
                          {{
                              $store.state.language === "en"
                                  ? "Bet"
                                  : "ထိုးမည်"
                          }}
                      </div>
                      <!-- <Loading
                          color="#ffffff"
                          v-if="isBet"
                          size="24"
                          class="px-2 py-0"
                      /> -->
                  </button>
              </div>
          </div>
          <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex justify-content-start px-0">
                    <span class="text-dark text-sm font-weight-400 mr-2" @click="openColorsModal()">{{ $store.state.language === "en" ? "Meaning of Color!" : "အရောင်များ၏ အဓိပ္ပာယ်။" }}</span>
                    <span class="badge badge-dark text-white" style="background: #212529"><i class="fa fa-info" @click="openColorsModal()"></i> </span>
              </div>
              <div class="col-6 d-flex justify-content-end px-0">
                <i class="fa fa-redo my-auto text-lg" style="color: #212529" @click="removeHistory()"></i>
                <span class="my-auto text-dark text-sm font-weight-400 ml-2" @click="removeHistory()">{{ $store.state.language === "en" ? "All Clear!" : "အားလုံးရှင်း။" }}</span>
              </div>
          </div>
      </div>
    </Sticky>
    <div class="d-flex p-0 flex-wrap main-wrapper justify-content-end">
        <div class="col-lg-4 col-md-6 col-12 px-1 mt--2 boxes" :class="!isGroup ? 'pb-5 mb-5' : ''">
            <Table @numbers="getNumbers" :allNumbers="allNumbers" :isTwod="true" :on-my-event="myEventSource" :twod="twod"/>
        </div>

        <div class="col-lg-4 col-md-6 col-12 d-flex flex-column px-2 boxes pb-5 mb-5" ref="groupBox">
            <GroupBox @numbers="getGroupNumbers" :allNumbers="allNumbers" class="mt-2" :on-my-event="myEventSource"/>
        </div>
    </div>

    <!-- Preview Voucher -->
    <div class="col-lg-4 col-md-6 px-2">
        <div class="row">
            <!-- === only amount === -->
            <div class="col-md-4 cart-voucher">
                <div
                    class="modal fade"
                    id="mobile-voucher"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    aria-hidden="true"
                >
                    <div
                        class="
                            modal-dialog
                            modal-
                            modal-dialog-centered
                            modal-sm
                        "
                        role="document"
                    >
                        <div class="modal-content">
                            <div
                                class="text-center modal-cross"
                                @click="closeModal"
                            >
                                X
                            </div>
                            <div class="modal-body p-0">
                                <PreviewVoucher
                                    :remark="remark"
                                    v-if="isMobile"
                                    :datas="datas"
                                    @remove="removeHistory"
                                    :isTwod="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-lg-4 col-md-6 px-2">
            <div class="row">
                <!-- === only amount === -->
                <div class="col-md-4 cart-voucher">
                    <div
                        class="modal fade"
                        id="colors-modal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="modal-form"
                        aria-hidden="true"
                    >
                        <div
                            class="
                                modal-dialog
                                modal-
                                modal-dialog-centered
                                modal-sm
                            "
                            role="document"
                        >
                            <div class="modal-content">
                                <div class="modal-heading bg-dark py-3" style="border-top-right-radius:0.4375rem; border-top-left-radius:0.4375rem">
                                      <h4 class="text-center text-white">
                                        အရောင် အဓိပ္ပါယ် <br> 100% ဖြစ်သောအခါ ထီနံပါတ်များကို ပိတ်ပါမည်။
                                      </h4>
                                    </div>
                                <div class="modal-body px-5 d-flex py-3">
                                    <ul>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: green; width:20px; height: 20px; border-radius: 50%;"></div> 50% အောက်
                                        </div>
                                      </li>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: yellow; width:20px; height: 20px; border-radius: 50%;"></div> 50% မှ 75% အတွင်း 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: red; width:20px; height: 20px; border-radius: 50%;"></div> 90% အထက် 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: #7c7676; width:20px; height: 20px; border-radius: 50%;"></div> အပြည့် (100%)
                                        </div>
                                      </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


  </div>
</template>
<script>
import { newEventSource } from 'vue-parent-emit';
import { Sticky, Loading, Dialog, Toast } from 'vant';
import Table from '../../components/Table.vue';
import GroupBox from '../../components/GroupBox.vue';
import { mapActions } from 'vuex';
import PreviewVoucher from '../../components/PreviewVoucher.vue';

export default {
components: {
  Table,
  GroupBox,
  // NormalBox,
  // NoticeBar,
  PreviewVoucher,
  Sticky,
  Loading,
},
data() {
  return {
    myEventSource: newEventSource(),
    datas: [],
    selected_data: {},
    isMobile: false,
    remark: '',
    isGroup: false,
    interval: false,
    noticeText: null,
    isBet: false,
    voucherId: this.slipId(),
    voucherLength: null,
    twod: {
      latest_bet_m: "11:45",
      latest_bet_e: "4:20",
    },
    betCloseTime: {},
    errors: {},
    numbers: [],
    numberAmount: null,
    allNumbers: this.$store.state.twod_numbers,
    isReverse: false,
  };
},

watch: {
  numberAmount: {
    handler() {
      this.datas = this.datas.map(item => ({
        ...item,          // Spread the existing properties of the object
        solo_amount: this.numberAmount  // Add or update the `solo_amount` property
    }));
    },
    deep: true,
  },
},

methods: {
  ...mapActions(['fetchUser']),
  openColorsModal() {
      $('#colors-modal').modal('show');
    },
  setAmount(amount) {
    const currentHour = this.createDateWithTimeZone().getHours();
    const draw = currentHour < 12 ? '12:00' : '4:30';
    for (let i = 0; i < this.datas.length; i += 1) {
      this.datas[i].solo_amount = amount;
      this.datas[i].draw = draw;
    }
  },
  dialog(text) {
    Dialog.alert({
      message: text,
      confirmButtonText:
                  this.$store.state.language === 'en'
                    ? 'Go Back'
                    : 'နောက်သို့ပြန်သွားမည်။',
    }).then(() => {
      // on close
    });
  },
  async twodData() {
    try {
      const res = await axios.get('2d/data');
      this.twod = res.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },
  betClose() {
    if (this.$route.query.draw === 'm') {
      const newBetTimeArray = this.twod.latest_bet_m
        .toString(10)
        .split('')
        .map((t) => t);
        // console.log('newBetTimeArray',newBetTimeArray)
      this.betCloseTime.hour = newBetTimeArray[0].toString()
                  + newBetTimeArray[1].toString();
      this.betCloseTime.minute = newBetTimeArray[3].toString()
                  + newBetTimeArray[4].toString();
      this.betCloseTime.isMorning = true;
      return `${this.twod.latest_bet_m} AM`;
    }

    // if (currentDate > eveningDraw) {
    //   this.betCloseTime.isForTomorrow = true;
    // }

    const newBetTimeArray = this.twod.latest_bet_e
      .toString(10)
      .split('')
      .map((t) => t);
    this.betCloseTime.hour = parseInt(newBetTimeArray[0], 12) + 12;
    this.betCloseTime.minute = newBetTimeArray[2].toString() + newBetTimeArray[3].toString();
    return `${this.twod.latest_bet_e} PM`;
  },
  async checkCurrentVouchers() {
    try {
      const res = await axios.post('/vouchers/check', {
        type: 'twod',
      },{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
      this.voucherLength = res.data.voucherLength;
    } catch (error) {
      console.log(error);
    }
  },
  dialog(text) {
            Dialog.alert({
                message: text,
                confirmButtonText:
                    this.$store.state.language === "en"
                        ? "Go Back"
                        : "နောက်သို့ပြန်သွားမည်။",
            }).then(() => {
                // on close
            });
        },
  async preBet() {
    try {
      if (this.isBet) {
          return false;
      }
      if (this.voucherId === this.slipId()) {
          return false;
      }
      if (this.datas.length == 0) {
          const dataError = this.$store.state.language === "en"
                            ? "Please pick some numbers!"
                            : "နံပါတ်အချို့ ရွေးပေးပါ။";
          this.dialog(dataError);
          return false;
      }
      if (!this.numberAmount) {
          const dataError = this.$store.state.language === "en" ? 'Please enter amount' : 'ငွေပမာဏထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }
      if (this.numberAmount < 100) {
          this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';

          const dataError = this.$store.state.language === "en" ? 'Please enter at least 100 MMK' : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }

      if(this.isReverse){
       // Step 1: Reverse numbers in selected data (`datas`)
        const reversedNumbers = this.datas.map(item => ({
          ...item,
          number: String(item.number).split('').reverse().join('') // Reverse the number
        }));

        // Step 2: Filter `allNumbers` based on reversed numbers
        const filteredFromAllNumbers = this.allNumbers.filter(item =>
          reversedNumbers.some(reversedItem => reversedItem.number == item.number)
        );

        // Step 3: Merge filtered data with selected data (`datas`)
        const combinedData = [
          ...this.datas,
          ...filteredFromAllNumbers
        ];

        // Step 4: Remove duplicates based on `number`
        this.datas = combinedData.reduce((acc, current) => {
          const x = acc.find(item => item.number == current.number);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          let draw = '4:30';
          if(this.$route.query.draw === 'm') {
            draw = '12:00';
          }
          this.datas = this.datas.map(item => ({
              ...item,          // Spread the existing properties of the object
              solo_amount: this.numberAmount,  // Add or update the `solo_amount` property
              draw: draw
          }));
      }
     
    $("#mobile-voucher").modal("show");
    } catch (error) {
        console.log(error);
    }
  },

  slipId() {
    return this.voucherLength;
  },
  /* eslint-disable */
      async finalBet() {
          this.setAmount(this.numberAmount);
          const userRes = await this.$auth.fetch();
          this.voucherId = this.slipId();
          this.$store.commit("setAmount", userRes.data.data.amount);
          this.isBet = true;
          this.betClose();
          const currentDate = this.createDateWithTimeZone();
          const newDate = this.createDateWithTimeZone();
          // if (this.isTwod) {
              newDate.setHours(
                  parseInt(this.betCloseTime.hour, 10),
                  parseInt(this.betCloseTime.minute, 10),
                  0
              );
          try {
              if (this.datas.length > 0) {
                  this.newRemark = JSON.parse(JSON.stringify(this.remark));
                  if (this.twod.isOpen && !this.betCloseTime.isForTomorrow && currentDate > newDate) {
                      const dataError =
                          this.$store.state.language === "en"
                              ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                              : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )";
                      this.dialog(dataError);
                      this.isBet = false;
                      this.voucherId = null;
                      return false;
                  }
                  for (let i = 0; i < this.datas.length; i += 1) {
                      this.datas[i].bet_date = this.currentTime();
                  }
                  if (
                      this.$store.state.amount >= this.totalAmount() ||
                      this.type === "admin"
                  ) {
                      const res = await axios.post("/vouchers", {
                          datas: this.datas,
                          voucherId: this.voucherId,
                          betTime: this.currentTime(),
                          isTwod: true,
                          remark: this.remark,
                          draw_date: null,
                      });
                      this.checkCurrentVouchers();
                      if (res.data.message) {
                          this.dialog(
                              this.$store.state.language === "en"
                                  ? "Bet Close Now! Today may be holiday or Temporary closed for now!"
                                  : "အခုထိုးလို့မရသေးပါ။ ပိတ်ထားပါသည်။ ( ပိတ်ရက် ဒါမှမဟုတ် ယာယီပိတ်ထားခြင်း ဖြစ်နိုင်ပါသည်။ )"
                          );
                      } else {
                          if (res.data.overLimitNumbers.length > 0) {
                              const dataError = `${
                                  this.$store.state.language === "en"
                                      ? "Limited number amount"
                                      : "ကန့်သတ်ထားသောနံပါတ်နှင့် ငွေပမာဏ"
                              } : ${JSON.stringify(res.data.overLimitNumbers)
                                  .replace(
                                      /[`~!@#$%^&*()_|+\-=?;'".<>\{\[\]\/]/gi,
                                      ""
                                  )
                                  .replace(/[\}\\\/]/g, ", ")
                                  .toString()
                                  .bold()
                                  .fontsize(4)}`;
                              this.dialog(dataError);
                              this.removeClosedNumbers(
                                  res.data.closedNumbers,
                                  res.data.overLimitNumbers
                              );
                          } else {
                              this.removeClosedNumbers(
                                  res.data.closedNumbers
                              );
                          }

                          if (this.type !== "admin") {
                              this.$store.commit(
                                  "setAmount",
                                  (this.$store.state.amount -=
                                      this.totalAmount())
                              );
                              await this.$auth.fetch();
                          }

                          // $('#mobile-voucher').modal('hide');
                          if (res.data.closedNumbers.length > 0) {
                              const dataError = `${
                                  this.$store.state.language === "en"
                                      ? "Closed number(s)"
                                      : "ပိတ်ထားသော နံပါတ်များ"
                              } : ${res.data.closedNumbers
                                  .toString()
                                  .bold()
                                  .fontsize(4)}`;
                              this.dialog(dataError);
                          }

                          this.removeHistory();
                          this.remark = null;
                          Toast.success(
                            this.$store.state.language === 'en'
                              ? 'Success!'
                              : 'အောင်မြင်သည်။',
                          );
                      }
                  } else {
                      const balanceError =
                          this.$store.state.language === "en"
                              ? "You Don't Have Sufficient Balance"
                              : "အကောင့်ထဲတွင် ပိုက်ဆံအလုံအလောက် မရှိပါ။";
                      this.dialog(balanceError);
                  }
              } else {
                  const dataError =
                      this.$store.state.language === "en"
                          ? "Please pick some numbers!"
                          : "နံပါတ်အချို့ ရွေးပေးပါ။";
                  this.dialog(dataError);
              }
              this.isBet = false;
              this.voucherId = null;
          } catch (error) {
              console.log(error);
              this.isBet = false;
              this.voucherId = null;
          }
          return true;
      },
      changeOverLimitedNumberAmount(list) {
          for (let i = 0; i < list.length; i += 1) {
              const index = this.datas.findIndex(
              (data) => data.number === list[i].number,
              );
              this.datas[index].solo_amount = list[i].amount;
              console.log(this.datas);
          }
      },
      removeClosedNumbers(list, limitedAmounts = null) {
          if (limitedAmounts) {
              this.changeOverLimitedNumberAmount(limitedAmounts);
          }
          for (let i = 0; i < list.length; i += 1) {
              const index = this.datas.findIndex(
              (data) => data.number === list[i],
              );
              console.log(index);
              this.datas.splice(index, 1);
          }
          $('#voucher').modal('show');
          },

      currentTime() {
          return moment(this.createDateWithTimeZone()).format("lll");
      },
      totalAmount() {
          let total = 0;
          for (let i = 0; i < this.datas.length; i += 1) {
              total += parseInt(this.datas[i].solo_amount, 10);
          }
          if (this.$auth.check("comissioner")) {
              const comissionerValue =
                  total *
                  (this.$auth.user()["2d_comission"] / 100);
              total -= comissionerValue;
          }
          return total;
      },

  createDateWithTimeZone() {
    const moment = require('moment-timezone');
    const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
    // Extract individual components from moment object
    const year = dateInTimeZone.year();
    const month = dateInTimeZone.month();
    const day = dateInTimeZone.date();
    const hour = dateInTimeZone.hour();
    const minute = dateInTimeZone.minute();
    const second = dateInTimeZone.second();
    const millisecond = dateInTimeZone.millisecond();

    // Create a new Date object with the extracted components
    const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
    return newDateObject;
  },

  drawOptionGenerator() {
    const currentTime = this.createDateWithTimeZone();

    const evening = this.createDateWithTimeZone();
    evening.setHours(16, 25);

    if (currentTime > evening) {
      this.noticeText = this.$store.state.language === 'en' ? '2D is closed for Today.' : 'ယနေ့အတွက် နှလုံးထီ ထိုးခြင်း ပိတ်ပါပြီ ။';
      return true;
    }
    return false;
  },

  showGroupBox() {
    this.isGroup = !this.isGroup;
  $('#2d_group_box').modal('show');
  },
  sendEvent() {
    this.myEventSource.emit();
  },
  closeModal() {
    $('#mobile-voucher').modal('hide');
    $('#colors-modal').modal('hide');
  },
  getRemark(value) {
    this.remark = value;
  },
  getNumbers(value) {
      for (let i = 0; i < this.allNumbers.length; i += 1) {
          if(value.findIndex(obj => obj.number === this.allNumbers[i].number)  >= 0){
              this.allNumbers[i].isSelect = true;
          } else {
              this.allNumbers[i].isSelect = false;
          }
      }
  this.datas = value;
  let draw = '4:30';
  if(this.$route.query.draw === 'm') {
    draw = '12:00';
  }
  this.datas = this.datas.map(item => ({
        ...item,          // Spread the existing properties of the object
        solo_amount: this.numberAmount, // Add or update the `solo_amount` property
        draw: draw
    }));
  },
  getGroupNumbers(value) {
      for (let i = 0; i < this.allNumbers.length; i += 1) {
          this.allNumbers[i].isSelect = false;
      }
      for (let i = 0; i < value.length; i += 1) {
          this.allNumbers[this.allNumbers.findIndex(obj => obj.number === value[i].number)].isSelect = value[i].isSelect;
      }
  this.datas = value;
  let draw = '4:30';
  if(this.$route.query.draw === 'm') {
    draw = '12:00';
  }
  this.datas = this.datas.map(item => ({
        ...item,          // Spread the existing properties of the object
        solo_amount: this.numberAmount, // Add or update the `solo_amount` property
        draw: draw
    }));
  },
  removeHistory() {
    this.datas = [];
    $("#mobile-voucher").modal("hide");
    this.numberAmount = null;
    // this.sendEvent();
     this.sendEvent();
     this.allNumbers = this.allNumbers.map(item => ({
        ...item,
        isSelect: false
      }));
  },
  async fetchTwoNumbers() {
      try {
        const res = await axios.post('/twod_numbers', {
          limit: 'all',
        });
       this.allNumbers = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
},
destroyed() {
  clearInterval(this.interval);
},
unmounted() {
  clearInterval(this.interval);
},
async mounted() {
  const authUser = await this.fetchUser();
  if (!authUser) {
    this.$router.push('/auth/login');
  }
  this.$store.commit(
    'setAmount',
    parseInt(authUser.amount, 10),
  );

  this.fetchTwoNumbers();
  this.checkCurrentVouchers();
  this.twodData();

  this.interval = setInterval(() => this.drawOptionGenerator(), 3000);

  if (window.innerWidth < 768) {
    this.isMobile = true;
  }

  

  // if (this.$auth.check()) {
  //   this.$store.commit(
  //     'setAmount',
  //     parseInt(this.$auth.user().amount, 10),
  //   );
  // }
},
};
</script>
<style scoped>
.input-group {
  border: 1px solid #cccccc;
}
.modal-cross {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding-top: 3px;
  margin-left: auto;
  color: white;
  cursor: pointer;
  margin-right: -9px;
  margin-top: -9px;
  background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
  .boxes {
      margin-top: 6px;
      margin-bottom: 7px;
  }
  .main-wrapper {
      padding: 0px !important;
  }
  .preview-box {
      display: none;
  }
  .btn{
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
}

.number-reverse {
  background: #212529;
  color: white;
  border-radius: 5px;
  /* font-size: 20px; */
  font-weight: bold;
  cursor: pointer;
}

.number-reverse.active {
  background: #328B93;
  color: white;
}
</style>
