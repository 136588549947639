var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShow && _vm.match.is_show)?_c('div',[(_vm.type !== 'history')?_c('div',{staticClass:"match-container mb-2 px-1 py-2",class:_vm.match.is_big ? 'big-match-design' : ''},[(_vm.match.start_time)?_c('span',{staticClass:"text-md mt-1 text-dark pl-2"},[(_vm.match.is_big && _vm.$route.path !== '/maung-fb')?_c('span',{staticClass:"text-danger text-lg"},[_vm._v(" * * ")]):_vm._e(),_vm._v(" "),(_vm.match.league)?_c('span',{staticClass:"font-weight-bolder text-md"},[_vm._v(_vm._s(_vm.match.league)+" ")]):_vm._e(),(_vm.match.is_big && _vm.$route.path !== '/maung-fb')?_c('span',{staticClass:"text-danger"},[_vm._v(" ( "+_vm._s(_vm.$store.state.language === "en" ? "BIG MATCH" : "ပွဲကြီး")+" )")]):_vm._e(),_vm._v(" ( "+_vm._s(_vm.betTime(_vm.match.start_time))+" )")]):_vm._e(),_c('br'),_c('div',{staticClass:"upper-card w-100 d-flex justify-content-around",staticStyle:{"height":"40px"}},[_c('div',{staticClass:"upper-left-card d-flex",class:((_vm.betMatch.selected_team === _vm.match.first_team) && (!_vm.isGoalPor && !_vm.isSoneMa))
                        ? 'active'
                        : null,staticStyle:{"width":"40%","border-radius":"10px","background":"#000000","box-shadow":"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"},on:{"click":function($event){_vm.type === 'history'
                        ? ''
                        : _vm.checkTeam(_vm.match.first_team, null, _vm.match.first_team_id)}}},[_c('span',{staticClass:"my-auto mx-auto"},[_c('b',[_vm._v(" "+_vm._s(_vm.$store.state.language === "en" ? _vm.match.first_team_en : _vm.match.first_team))])])]),_c('div',{staticClass:"upper-left-card d-flex",staticStyle:{"width":"20%","border-radius":"10px","background":"#0084C7","box-shadow":"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}},[_c('span',{staticClass:"my-auto mx-auto"},[_c('b',[_vm._v(" "+_vm._s(_vm.$store.state.language === "en" ? "Draw" : "သရေ")+" ")])])]),_c('div',{staticClass:"upper-right-card d-flex",class:((_vm.betMatch.selected_team === _vm.match.second_team) && (!_vm.isGoalPor && !_vm.isSoneMa))
                        ? 'active'
                        : null,staticStyle:{"width":"40%","border-radius":"10px","background":"#000000","box-shadow":"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"},on:{"click":function($event){_vm.type === 'history'
                        ? ''
                        : _vm.checkTeam(_vm.match.second_team, null, _vm.match.second_team_id)}}},[_c('span',{staticClass:"text-center my-auto mx-auto"},[_c('b',[_vm._v(_vm._s(_vm.$store.state.language === "en" ? _vm.match.second_team_en : _vm.match.second_team))])])])]),_c('div',{staticClass:"upper-card w-100 d-flex justify-content-around",staticStyle:{"height":"40px"}},[_c('div',{staticClass:"upper-left-card d-flex",class:_vm.betMatch.selected_score === 'first_score' ? 'active': null,staticStyle:{"width":"50%","border-radius":"10px"},on:{"click":function($event){return _vm.checkTeam('first_score', _vm.match.first_kyay)}}},[_c('span',{staticClass:"my-auto mx-auto"},[_c('b',[_vm._v("x "+_vm._s(_vm.match.first_kyay))])])]),_c('div',{staticClass:"upper-left-card d-flex",class:_vm.betMatch.selected_score === 'draw_score' ? 'active': null,staticStyle:{"width":"50%","border-radius":"10px"},on:{"click":function($event){return _vm.checkTeam('draw_score', _vm.match.draw_kyay)}}},[_c('span',{staticClass:"my-auto mx-auto"},[_c('b',[_vm._v("x "+_vm._s(_vm.match.draw_kyay))])])]),_c('div',{staticClass:"upper-right-card d-flex",class:_vm.betMatch.selected_score === 'second_score' ? 'active': null,staticStyle:{"width":"50%","border-radius":"10px"},on:{"click":function($event){return _vm.checkTeam('second_score', _vm.match.second_kyay)}}},[_c('span',{staticClass:"text-center my-auto mx-auto"},[_c('b',[_vm._v("x "+_vm._s(_vm.match.second_kyay)+" ")])])])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }