<template>
    <div style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/66096b97-21ef-45e6-3246-81ddd53dd000/public'); min-height: 100vh; height: auto; background-size: cover;">
        <div class="d-flex flex-wrap pt-3">
            <!-- <div class="col-12">
                <div class="mx-0 card d-flex justify-content-between col-12 flex-row py-4">
                    <div class="col-4 d-flex flex-column">
                        <i class="fa-solid fa-file mx-auto"></i>
                        <span class="text-center mt-2">Records</span>
                    </div>
                    <div class="col-4 d-flex flex-column">
                        <i class="fa fa-calendar mx-auto"></i>
                        <span class="text-center mt-2">Closed Days</span>
                    </div>
                    <div class="col-4 d-flex flex-column">
                        <i class="fa-solid fa-list mx-auto"></i>
                        <span class="text-center mt-2">Winners List</span>
                    </div>
                </div>
            </div> -->


            <div class="col-lg-7 col-12 mx-auto d-flex" style="padding-left: 10px; padding-right: 10px;">
                <div class="mx-0 card d-flex justify-content-between col-12 flex-row py-3 mb-0" style="background-color: rgba(255,255,255,0.2) !important;">
                    <div class="col-4 d-flex flex-column">
                        <img
                            src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/29b18ae7-d373-44f2-84e6-ea9afc6f7700/public"
                            alt=""
                            class="mx-auto"
                            style="width: 30px; height: auto"
                            />
                        <span class="text-center mt-2 text-white">Records</span>
                    </div>
                    <div class="col-4 d-flex flex-column" @click="$router.push('/user/2d/closed')">
                        <img
                            src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f2c383ee-9527-4e01-d4e9-d2469194ee00/public"
                            alt=""
                            class="mx-auto"
                            style="width: 30px; height: auto"
                            />
                        <span class="text-center mt-2 text-white">Closed Days</span>
                    </div>
                    <div class="col-4 d-flex flex-column"  @click="$router.push('/user/2d/winner')">
                        <img
                            src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/e2a839c7-ad51-4dd4-afcb-38189ef1fd00/public"
                            alt=""
                            class="mx-auto"
                            style="width: 30px; height: auto"
                            />
                        <span class="text-center mt-2 text-white">Winners List</span>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-4 home-cards live-card" style="margin-top: 10px;">
                <div class="card twod-threed-cards">
                    <!-- <div class="card-header" v-if="isLive">
                        <div v-if="$store.state.language === 'en'">
                            2D live Result
                        </div>
                        <div v-else><h3>နှစ်လုံး တိုက်ရိုက်ရလဒ်</h3></div>
                        <div>
                            <b-spinner variant="secondary"></b-spinner>
                        </div>
                    </div> -->
                    <div class="card-body d-flex" v-if="isLive">
                        <div class="my-auto col-12">
                            <div class="text-center card">
                                <span class="live-result">{{
                                        twoDdata.live
                                    }}</span>
                            </div>
                            <div class="live-set-val-container d-flex col-12 p-0">
                                <div class="col-6 p-0">
                                    <small class="text-white">
                                        Set &nbsp;- &nbsp;
                                        <span class="live-number">{{
                                            twoDdata.live_set
                                        }}</span></small
                                    >
                                </div>
                                <div class="col-6 p-0">
                                    <small class="text-white"
                                        >Value &nbsp;- &nbsp;
                                        <span class="live-number">{{
                                            twoDdata.live_val
                                        }}</span></small
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body d-flex" v-if="!isLive">
                        <div
                            class="
                                my-auto
                                mx-auto
                                not-live-text
                                d-flex
                                w-100
                                p-3
                                h-100
                            "
                        >
                            <div
                                class="w-50 d-flex"
                                style="
                                    font-size: 80px;
                                    color: red;
                                    border-right: 1px solid grey;
                                "
                            >
                                <div
                                    class="my-auto mx-auto"
                                    v-if="twoDHistories.length > 0"
                                >
                                    {{ lastNumber }}
                                </div>
                            </div>
                            <div class="d-flex w-50">
                                <div
                                    class="my-auto mx-auto"
                                    style="font-size: 15px"
                                >
                                    <span style="color: #0095f8">
                                        {{
                                            $store.state.language === "en"
                                                ? "Last updated time"
                                                : "နောက်ဆုံး အပ်ဒိတ်"
                                        }}</span
                                    ><br />
                                    <span>{{ lastUpdatedDraw }}</span>
                                    <br />
                                    <span>{{ lastUpdatedDate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-12" style="padding-left: 10px; padding-right: 10px;">
                <table>
                    <thead>
                        <tr>
                            <th>နေ့စွဲ</th>
                            <th>မနက် - 12:01</th>
                            <th>ညနေ - 04:30</th>
                            <!-- <th>Won Amount</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(twodHistory, index) in twoDHistories" :key="index">
                            <td>{{ twodHistory.draw_date }}</td>
                            <td>{{ twodHistory.morning_result }}</td>
                            <td>{{ twodHistory.evening_result }}</td>
                            <!-- <td>{{Number(user.won).toLocaleString()}} MMK</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="bet-container">
                <button class="btn custom-btn" @click="toGoToTwodPage()">
                    <!-- <h3 class="text-uppercase" style="color: black"> -->
                        {{
                            $store.state.language === "en"
                                ? "BET"
                                : "ထိုးမည်"
                        }}    
                    <!-- </h3> -->
                </button>
            </div>

            <div class="col-4">
                <div
                    class="modal fade"
                    id="timeLimitModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                >
                    <div
                        class="modal-dialog modal- modal-dialog-centered"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-body my-5 mx-3 text-center d-flex flex-column">
                                <div class="col-12 p-0 pl-1 py-1" @click="draw = option.data" v-for="(option, index) in twodOptions" :key="index" v-show="!noticeText">
                                    <div class="time-cont text-dark py-2 text-center w-100" :class="draw === option.data ? 'active' : ''">
                                        {{option.label}}
                                    </div>
                                </div>
                                <div class="col-12 p-0 pl-1 py-1" v-if="noticeText">
                                    <div class="time-cont text-dark py-2 text-center w-100">
                                        {{noticeText}}
                                    </div>
                                </div>
                                <!-- <div class="col-12 p-0 pl-1 py-1" @click="draw = 'f'">
                                    <div class="time-cont text-dark py-2 text-center w-100" :class="draw === 'f' ? 'active' : ''" >
                                        4:30 PM
                                    </div>
                                </div> -->

                                <div class="col-12 mt-2">
                                    <button class="btn custom-btn py-3" @click="toGoToTwodPage(draw)">
                                    <!-- <h3 class="text-uppercase" style="color: black"> -->
                                        {{
                                            $store.state.language === "en"
                                                ? "BET"
                                                : "ထိုးမည်"
                                        }}    
                                    <!-- </h3> -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import createDateWithTimeZone from "../../../../libs/createDateWithTimeZone"
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      twoDdata: {},
      isLive: false,
      twoDHistories: {},
      internetModern: [],
      interval: null,
      threedData: {},
      twodOptions: [],
      draw: null,
    noticeText: null,
    interval: null,
    };
  },
  computed: {
    lastNumber() {
      return (
        this.twoDHistories[0]?.evening_result
                || this.twoDHistories[0]?.mid_evening_result || this.twoDHistories[0]?.morning_result || this.twoDHistories[0]?.mid_morning_result
      );
    },
    lastUpdatedDraw() {
      if (
        this.twoDHistories.length > 0
                && this.twoDHistories[0]?.evening_result
      ) return '4:30 PM';
      if (
        this.twoDHistories.length > 0
                && this.twoDHistories[0]?.mid_evening_result
      ) return '2:45 PM';
      if (
        this.twoDHistories.length > 0
                && this.twoDHistories[0]?.morning_result
      ) return '12:00 AM';
      return '10:45 AM';
    },
    lastUpdatedDate() {
      return this.twoDHistories[0]?.draw_date;
    },
  },
  methods: {
    ...mapActions(['fetchUser']),
    toGoToTwodPage(draw) {
        if(draw) {
            $('#timeLimitModal').modal('hide');
            this.$router.push(`/user/2d?draw=${draw}`);
        } else {
            $('#timeLimitModal').modal('show');
        }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },

   async drawOptionGenerator(){
        const currentTime = await createDateWithTimeZone();

        const morning = await createDateWithTimeZone();
        morning.setHours(11, 50);

        const evening = await createDateWithTimeZone();
        evening.setHours(15, 55);

        if(currentTime < morning){
            this.twodOptions = [
                {
                    data: 'm',
                    label: '12:01 PM',
                },
                {
                    data: 'f',
                    label: '4:30 PM',
                }
            ];
        } else if(currentTime < evening){
            this.twodOptions = [
                        {
                            data: 'f',
                            label: '4:30 PM',
                        }
                    ];
        }

        if(currentTime > evening){
            return this.noticeText = this.$store.state.language === "en" ? '2D is closed for Today.' : 'ယနေ့အတွက် နှစ်လုံးထီ ထိုးခြင်း ပိတ်ပါပြီ ။';
        }
    },
    async fetchTwoDliveresult() {
        const apiUrl = 'https://luke.2dboss.com/api/luke/twod-result-live';

        // Make a GET request using the fetch function
        fetch(apiUrl)
            .then(response => {
            // Check if the request was successful (status code 2xx)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON response
            return response.json();
            })
            .then(data => {
            this.twoDdata = data.data || {}
            })
            .catch(error => {
            // Handle errors
            console.error('Error fetching data:', error);
            });
        // },
    },
    async fetchModernInternet() {
      try {
        const res = await axios.get('/modern-internet');
        this.internetModern = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
        if (this.isLive) {
            this.fetchTwoDliveresult();
            this.interval = setInterval(() => this.fetchTwoDliveresult(), 5000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    ready() {
    //   const channel = this.$pusher.subscribe('live-socket-channel');
    //   channel.bind('twod-live', async (data) => {
    //     this.twoDdata = data;
    //   });

      //   channel.bind('twod-isLive', async (data) => {
      //     this.isLive = data;
      //   });
    },

    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        this.twoDHistories = twoDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  destroyed() {
    // this.$pusher.unsubscribe('live-socket-channel');
    clearInterval(this.interval);
  },
  unmount() {
    // this.$pusher.unsubscribe('live-socket-channel');
    clearInterval(this.interval);
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }

    this.fetchTwoDdata();
    this.fetchTwoThreeDHistories();
    await this.drawOptionGenerator();
    this.interval = setInterval(async() => await this.drawOptionGenerator(), 30000);
  },
};
</script>
<style scoped>
.time-cont {
    border: 1px solid dimgrey;
    border-radius: 5px;
    font-size: 1.1rem;
}

.time-cont.active {
    color: white !important;
    background: dimgrey;
}

table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    th,
    td {
        padding: 10px !important;
        background-color: rgba(255,255,255,0.2);
        color: #fff;
    }

    th {
        text-align: left;
    }

    thead {
        th {
            background: rgb(51, 139, 147);
            color: black;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: rgba(95, 111, 180, 0.3);
            }
        }
        td {
            position: relative;
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(255,255,255,0.2);
                    z-index: -1;
                }
            }
        }
    }


.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 70px;
    width: 100px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.main-image-wrapper img {
    width: 100%;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
@keyframes live-number-animation {
    from {
        color:#0084C7;
    }
    to {
        color: black;
    }
}

@media (max-width: 768px) {
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 30px;
        z-index: 1;
        font-size: 13px;
        color: #172b4c;
        background: rgba(255, 242, 0, 0.7);
    }
    .live-number {
        font-weight: bold;
        font-size: 15px;
    }
    .live-result {
        font-weight: bolder;
        font-size: 100px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>
