<template>
    <div>
        <v-select
            v-if="type === 'number'"
            v-model="data[field]"
            :reduce="(number) => number.number"
            label="number"
            :options="numbers"
            :selectable="(number) => number.isOpen"
            :closeOnSelect="true"
            inputmode="decimal"
        ></v-select>
        <v-select
            v-else-if="type === 'draw'"
            v-model="data[field]"
            :reduce="(draw) => draw.data"
            label="label"
            :options="drawOptions"
        ></v-select>
        <v-select
            v-else
            v-model="data[field]"
            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, '0']"
        ></v-select>
    </div>
</template>
<script>
export default {
  props: ['data', 'type', 'field', 'numbers'],
  data() {
    return {
      drawOptions: [
        // {
        //   label: '10:45 AM',
        //   data: '10:45',
        // },
        {
          label: '12:00 AM',
          data: '12:00',
        },
        // {
        //   label: '2:45 PM',
        //   data: '2:45',
        // },
        {
          label: '4:30 PM',
          data: '4:30',
        },
      ],
    };
  },
};
</script>
