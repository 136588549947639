<template>
  <div class="custom-select-test">
    <v-select
        v-model="data[field]"
        :reduce="(account) => account.id"
        label="name"
        :options="accounts"
        placeholder="Account"
        style="color: #ACB5BD; border-radius: 7px; background-color: rgba(255,255,255,0.2) !important; padding-top: 5px; padding-bottom: 5px; padding-left: 5px;"
    >
      <template slot="option" slot-scope="option">
          <!-- <img style="width:20px; height: 20px; margin-left: -10px; border-radius: 3px;" :src="option.link" /> -->
          <!-- <span style="padding-top: 400px !important; padding-left: 10px !important">{{option.name }}</span> -->
          <span>{{option.name }}</span>
      </template>
    </v-select>
  </div>
    
</template>
<script>
export default {
  props: ['data', 'field', 'accounts'],
};
</script>