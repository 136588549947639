var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShow && _vm.match.is_show)?_c('div',[(_vm.type !== 'history')?_c('div',{staticClass:"match-container mb-2 px-1 py-2",class:_vm.match.is_big ? 'big-match-design' : ''},[(_vm.match.start_time)?_c('span',{staticClass:"text-md mt-1 text-dark pl-2"},[(_vm.match.is_big && _vm.$route.path !== '/maung-fb')?_c('span',{staticClass:"text-danger text-lg"},[_vm._v(" * * ")]):_vm._e(),_vm._v(" "),(_vm.match.league)?_c('span',{staticClass:"font-weight-bolder text-md"},[_vm._v(_vm._s(_vm.match.league)+" ")]):_vm._e(),(_vm.match.is_big && _vm.$route.path !== '/maung-fb')?_c('span',{staticClass:"text-danger"},[_vm._v(" ( "+_vm._s(_vm.$store.state.language === "en" ? "BIG MATCH" : "ပွဲကြီး")+" )")]):_vm._e(),_vm._v(" ( "+_vm._s(_vm.betTime(_vm.match.start_time))+" )")]):_vm._e(),_c('br'),_c('div',{staticClass:"upper-card w-100 d-flex justify-content-around",staticStyle:{"height":"40px"}},[_c('div',{staticClass:"upper-left-card d-flex",class:((_vm.betMatch.selected_team === _vm.match.first_team) && (!_vm.isGoalPor && !_vm.isSoneMa))
                        ? 'active'
                        : null,staticStyle:{"width":"50%","border-radius":"10px","background":"#000000","box-shadow":"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"},on:{"click":function($event){_vm.type === 'history'
                        ? ''
                        : _vm.checkTeam(_vm.match.first_team, null, _vm.match.first_team_id)}}},[_c('span',{staticClass:"my-auto mx-auto"},[_c('b',[_vm._v(" "+_vm._s(_vm.$store.state.language === "en" ? _vm.match.first_team_en : _vm.match.first_team))])])]),_c('div',{staticClass:"upper-right-card d-flex",class:((_vm.betMatch.selected_team === _vm.match.second_team) && (!_vm.isGoalPor && !_vm.isSoneMa))
                        ? 'active'
                        : null,staticStyle:{"width":"50%","border-radius":"10px","background":"#000000","box-shadow":"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"},on:{"click":function($event){_vm.type === 'history'
                        ? ''
                        : _vm.checkTeam(_vm.match.second_team, null, _vm.match.second_team_id)}}},[_c('span',{staticClass:"text-center my-auto mx-auto"},[_c('b',[_vm._v(_vm._s(_vm.$store.state.language === "en" ? _vm.match.second_team_en : _vm.match.second_team))])])])]),_c('div',{staticClass:"upper-card d-flex flex-wrap px-0 mx-0 justify-content-center flex-shrink-1",staticStyle:{"height":"auto","width":"100%"}},_vm._l((_vm.match.correctScores),function(correctScore,index){return _c('div',{key:index,staticClass:"upper-left-card d-flex p-0 m-1",class:_vm.betMatch.correct_score === correctScore.score ? 'active': null,staticStyle:{"border-radius":"10px","height":"50px","width":"30%"},on:{"click":function($event){return _vm.checkTeam(correctScore.score, correctScore.odds)}}},[_c('span',{staticClass:"my-auto mx-auto text-center pt-2"},[_c('p',{staticClass:"badge my-auto",class:_vm.betMatch.correct_score === correctScore.score ? 'badge-dark text-white': 'badge-danger',staticStyle:{"font-size":"14px","width":"50px","padding-top":"2px","padding-bottom":"2px"}},[_c('b',[_vm._v(" "+_vm._s(correctScore.odds)+" ")])]),_c('br'),_c('span',{staticClass:"pt-2"},[_c('b',{staticStyle:{"letter-spacing":"0.3rem"}},[_vm._v(_vm._s(correctScore.score))])])])])}),0)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }